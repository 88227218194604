export interface ApiLinterRules {
  rules: ApiLinterRule[];
}

export interface ApiLinterRule {
  title: string;
  url: string;
  code: string;
  type: ViolationType;
  is_active: boolean;
}

export interface ApiLinterViolations {
  api_definition: string;
  external_id: string;
  message: string;
  violations: Violation[];
  violations_count: ViolationsCount;
}

export interface Violation {
  violation_type: string;
  title: string;
  description: string;
  rule_link: string;
  pointer: string;
}

export enum ViolationType {
  MUST = "must",
  SHOULD = "should",
  MAY = "may",
  HINT = "hint",
}

export interface ViolationsCount {
  [ViolationType.MUST]: number;
  [ViolationType.SHOULD]: number;
  [ViolationType.MAY]: number;
  [ViolationType.HINT]: number;
}
